import {
  Box,
  Button,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import appImages from "../../constants/AppImage";
import PrimaryHeading from "../../components/Common/Typography/PrimaryHeading";
import TitleStrip from "../../components/Common/TitleStrip";

const {
  documentProtectionBackGroundImage,
  newHomePage: {
    document1,
    document2,
    inValidDocument,
    validDocument,
    documentVerification,
  },
} = appImages;

const useStyle = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    height: "auto",
    minHeight: "100vh",
    objectFit: "contain",
    backgroundSize: "cover",
    backgroundImage: `url(${documentProtectionBackGroundImage})`,
  },
  content: {
    zIndex: 1,
    color: "#fff",
    padding: "100px",
    "&>h1": {
      fontSize: "50px",
      fontWeight: 600,
      color: "#fff",
      width: "50%",
      [theme.breakpoints.down("md")]: {
        width: "80%",
        fontSize: "30px",
        marginTop: "25px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        fontSize: "30px",
        marginTop: "25px",
      },
    },

    [theme.breakpoints.down("sm")]: {
      padding: "50px",
      textAlign: "center",
    },
    "&>a": {
      marginTop: "50px",
    },
  },

  howItWorksSection: {
    marginTop: "50px",
    textAlign: "center",
    padding: "0 20px",
  },

  documentExampleSection: {
    marginTop: "30px",
    padding: "20px",
    "&>h4": {
      fontWeight: 600,
      color: theme.palette.common.white,
    },
  },

  exampleDocument: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: "50px",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: "20px",
      alignItems: "center",
    },
  },
  documentBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "15px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
  },

  documentImage: {
    maxWidth: "100%",
    height: "400px",
    objectFit: "fill",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "auto",
    },
  },

  documentDescription: {
    color: theme.palette.common.white,
    fontWeight: 400,
    fontSize: "18px",
    marginTop: "10px",
    textAlign: "center",
    padding: "15px",
    borderRadius: "8px",
    fontStyle: "italic",
  },

  heading: {
    fontWeight: "600",
    color: theme.palette.common.white,
    marginBottom: "30px",
    textTransform: "uppercase",
    fontSize: "45px",
  },
  documentVerificationContainer: {
    display: "flex",
    padding: "40px",
    gap: "25px",
    justifyContent: "space-between",
    "&>h4": {
      fontWeight: 600,
      color: "#fff",
      width: "50%",
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        width: "auto",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        marginBottom: "25px",
      },
    },
    "&>img": {
      width: "100%",
      height: "400px",
      borderRadius: "10px",
      [theme.breakpoints.down("sm")]: {
        height: "auto",
      },
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  documentProtectionHeading: {
    display: "flex",
    fontSize: "45px",
    fontWeight: "bold",
    color: theme.palette.common.white,
    textTransform: "uppercase",
    justifyContent: "center",
    textAlign: "center",
  },
  newButton: theme.overrides.MuiButton.newButton,
}));

const DocumentProtection = () => {
  const classes = useStyle();
  return (
    <Box className={classes.root}>
      <TitleStrip title="Document Protection" />
      <Box className={classes.content}>
        <Typography variant="h1">
          Simple, easy document protection without the original document ever
          leaving your secure perimeter
        </Typography>
        <Button href="/document-protection" className={classes.newButton}>
          Learn more
        </Button>
      </Box>

      <Box className={classes.howItWorksSection}>
        <Box className={classes.heading}>How It Works</Box>

        {/* ================== VALID DOCUMENT EXAMPLE START ==================  */}
        <Box className={classes.documentExampleSection}>
          <Typography variant="h4">Valid document example</Typography>

          <Grid container spacing={3} className={classes.exampleDocument}>
            <Grid item xs={12} sm={6} md={4}>
              <Box className={classes.documentBox}>
                <img
                  src={document2}
                  alt="Document Example 1"
                  className={classes.documentImage}
                />
                <Typography className={classes.documentDescription}>
                  Example from an internal or third-party lab
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Box className={classes.documentBox}>
                <img
                  src={validDocument}
                  alt="Valid Document"
                  className={classes.documentImage}
                />
                <Typography className={classes.documentDescription}>
                  This image shows the blockchain proof of a valid document. The
                  protection tool allows an interested party to compare the
                  document in question against the original held by the issuer
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {/* ================== VALID DOCUMENT EXAMPLE END ==================  */}

        {/* ================== INVALID DOCUMENT EXAMPLE START ==================  */}
        <Box className={classes.documentExampleSection}>
          <Typography variant="h4">Fraudulent document example</Typography>

          <Grid container spacing={3} className={classes.exampleDocument}>
            <Grid item xs={12} sm={6} md={4}>
              <Box className={classes.documentBox}>
                <img
                  src={document1}
                  alt="Document Example 2"
                  className={classes.documentImage}
                />
                <Typography className={classes.documentDescription}>
                  This image shows an unverified match. The document uploaded by
                  the verifying entity (retailer, customer...) does not match
                  the original COA issued by the lab
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Box className={classes.documentBox}>
                <img
                  src={inValidDocument}
                  alt="Invalid Document"
                  className={classes.documentImage}
                />
                <Typography className={classes.documentDescription}>
                  This image shows the results... "not a verified match" when
                  the interested party tries to validate the tampered,  COA
                  through the portal
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {/* ================== INVALID DOCUMENT EXAMPLE END ==================  */}
      </Box>

      {/* ================== DOCUMENT VERIFICATION SECTION START ==================  */}
      <Box className={classes.documentProtectionHeading}>
        Document verification
      </Box>
      <Box className={classes.documentVerificationContainer}>
        <Typography variant="h4">
          Automatically verify supplier products, related documents, and
          credentials
        </Typography>
        <img src={documentVerification} />
      </Box>
      {/* ================== DOCUMENT VERIFICATION SECTION END ==================  */}
    </Box>
  );
};

export default DocumentProtection;
