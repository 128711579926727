import {
  Box,
  Divider,
  Grid,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import appImages from "../../constants/AppImage";
import { AppConstant } from "../../constants";

const {
  homeFooter: { address, email, footerLinks },
} = AppConstant;
const { logo } = appImages;
const useStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#BFBFBF",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  footerHeader: {
    color: "#000",
    fontWeight: "bolder",
    marginBottom: "25px",
  },
  list: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    cursor: "pointer",
    "&>a": {
      wordBreak: "break-word",
    },
  },
  newButton: theme.overrides.MuiButton.newButton,
  footerCopyRight: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    padding: "10px 15px",
    "&>p": {
      color: theme.palette.common.white,
      textAlign: "center",
    },
  },
  email: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      marginTop: "15px",
    },
  },
  footerAddress: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: "15px",
    "&>p": {
      marginLeft: "15px",
    },
  },
  footerLinks: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    width: "100%",
    margin: "22px 0 36px 0",
    "&>a": {
      width: "max-content",
    },
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
}));

const NewFooter = () => {
  const classes = useStyle();
  return (
    <>
      <Grid className={classes.root}>
        <Grid
          style={{
            maxWidth: "1074px",
            justifyContent: "center",
            gap: "10px 20px",
            padding: "25px",
          }}
        >
          <Box className={classes.footerAddress}>
            <Link href="/">
              <img src={logo} width={53} height={53} alt="HealthLOQ" />
            </Link>
            <Typography variant="body2">
              {address}&nbsp;
              <Link
                href={`mailto:${email}`}
                style={{
                  color: "blue",
                }}
                variant="body2"
              >
                {email}
              </Link>
            </Typography>
          </Box>
          <Divider style={{ height: "2px" }} />
          <Box
            className={classes.footerLinks}
            gridRowGap={10}
            gridColumnGap={20}
          >
            {footerLinks?.map((item, key) => {
              return (
                <Link href={item?.href} key={key} variant="body2">
                  {item?.label}
                </Link>
              );
            })}
          </Box>
        </Grid>
      </Grid>
      <Box className={classes.footerCopyRight}>
        <Typography variant="body2">
          © Copyright {new Date().getFullYear()} HealthLOQ LLC. All rights
          reserved
        </Typography>
      </Box>
    </>
  );
};

export default NewFooter;
