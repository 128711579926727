import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Link,
  Toolbar,
  makeStyles,
  useScrollTrigger,
  Button,
  IconButton,
  Grid,
  Drawer,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { AppImages, AppConstant } from "../constants";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SimpleSelect from "./Common/FormComponents/SimpleSelect";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom";
import CalendlyPage from "./Common/CalendlyPage";

const { logo } = AppImages;
const { header } = AppConstant;

const useStyle = makeStyles((theme) => ({
  headerRoot: {
    height: "100px",
    display: "flex",
    justifyContent: "center",
    backgroundColor: theme.palette.common.white,
    "&>div": {
      padding: 0,
      height: "100%",
    },
    [theme.breakpoints.down("md")]: {
      "&>div": {
        padding: 20,
      },
    },
  },
  headerContent: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "&>a": {
      "&>img": {
        width: "75px",
        height: "75px",
      },
    },
  },
  headerRightButtons: {
    display: "flex",
    "& button": {
      "&:first-child": {
        marginRight: 15,
        "&>span": {
          "&>svg": {
            width: 32,
            height: 32,
          },
        },
      },
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  registerOrLoginText: {
    marginRight: 15, // Adjust margin as needed
    marginTop: 15,
  },
  headerLinks: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  mobileMenu: {
    display: "none",
    height: "auto",
    [theme.breakpoints.down("md")]: {
      display: "flex",
    },
  },
  menuButton: {
    display: "grid",
    justifyItems: "center",
  },
  menuIcon: {
    display: "none",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      display: "flex",
    },
  },
  mobileMenuContainer: {
    display: "grid",
    minWidth: "230px",
    marginTop: "25px",
    gap: "18px",
    marginBottom: "30px",
    justifyItems: "center",
  },
  userIcon: {
    background: theme.palette.primary.light,
    "&:hover": {
      backgroundColor: "#000",
      "&>span": {
        "&>svg": {
          color: "#fff",
        },
      },
    },
  },
}));

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const defaultState = {
  Solutions: "",
  Partners: "",
  Services: "",
  Consumers: "",
  "About Us": "",
};

export default function Header(props) {
  const classes = useStyle();
  const [state, setState] = useState(defaultState);
  const [openMenu, setOpenMenu] = useState(false);
  const [userIconAnchorEl, setUserIconAnchorEl] = useState(null);
  const history = useHistory();
  header.Consumers = header.Consumers?.map((item) =>
    item?.label === "Report Recycling"
      ? {
          ...item,
          value: AppConstant[window.location.hostname]?.ReportRecyclingLink,
        }
      : item
  );

  const handleSelect = (key, value) => {
    setState({ ...defaultState, [key]: value });
    history.push(value);
  };
  useEffect(() => {
    for (const [key, value] of Object.entries(header)) {
      const findCurrPage = value.find(
        (item) => item?.value === window.location.pathname
      );
      if (findCurrPage) {
        setState((pre) => ({ ...pre, [key]: findCurrPage?.label }));
      }
    }
  }, [window.location.pathname]);
  return (
    <ElevationScroll {...props}>
      <AppBar className={classes.headerRoot}>
        <Toolbar className="bodyMaxWidth">
          <Box className={classes.headerContent}>
            <Link href="/">
              <img src={logo} alt="HealthLOQ Logo" />
            </Link>
            <Grid
              onClick={() => setOpenMenu(!openMenu)}
              className={classes.menuIcon}
            >
              <MenuIcon color="primary" />
            </Grid>
            <Box className={classes.headerLinks} gridColumnGap={6}>
              <SimpleSelect
                value={state.Solutions}
                onChange={(value) => handleSelect("Solutions", value)}
                options={header.Solutions}
                label="Solutions"
              />
              <SimpleSelect
                value={state.Partners}
                onChange={(value) => handleSelect("Partners", value)}
                options={header.Partners}
                label="Partners"
              />
              <SimpleSelect
                value={state["Services"]}
                onChange={(value) => handleSelect("Services", value)}
                options={header["Services"]}
                label="Services"
              />
              <SimpleSelect
                value={state.Consumers}
                onChange={(value) => handleSelect("Consumers", value)}
                options={header.Consumers}
                label="Consumers"
              />
              <SimpleSelect
                value={state["About Us"]}
                onChange={(value) => handleSelect("About Us", value)}
                options={header["About Us"]}
                label="About Us"
              />
            </Box>
            <Box className={classes.headerRightButtons}>
              <IconButton
                className={classes.userIcon}
                onClick={(e) => setUserIconAnchorEl(e.currentTarget)}
              >
                <AccountCircleIcon />
              </IconButton>
              <Typography
                variant="body1"
                className={classes.registerOrLoginText}
              >
                <Link href="/register">Register</Link> or&nbsp;
                <Link href="/login">Login</Link>
              </Typography>
              <CalendlyPage>
                <Button>Request a Demo</Button>
              </CalendlyPage>
            </Box>
            <Drawer
              variant="temporary"
              anchor="right"
              open={openMenu}
              onClose={() => setOpenMenu(false)}
              ModalProps={{ keepMounted: true }}
              className={classes.mobileMenu}
            >
              <CloseIcon
                onClick={() => setOpenMenu(false)}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: 0,
                }}
              />
              <Grid className={classes.mobileMenuContainer}>
                <Link href="/">
                  <img src={logo} width={50} alt="HealthLOQ Logo" />
                </Link>
                <SimpleSelect
                  value={state.Solutions}
                  onChange={(value) => handleSelect("Solutions", value)}
                  options={header.Solutions}
                  label="Solutions"
                />
                <SimpleSelect
                  value={state.Partners}
                  onChange={(value) => handleSelect("Partners", value)}
                  options={header.Partners}
                  label="Partners"
                />
                <SimpleSelect
                  value={state["Services"]}
                  onChange={(value) => handleSelect("Services", value)}
                  options={header["Services"]}
                  label="Services"
                />
                <SimpleSelect
                  value={state.Consumers}
                  onChange={(value) => handleSelect("Consumers", value)}
                  options={header.Consumers}
                  label="Consumers"
                />
                <SimpleSelect
                  value={state["About Us"]}
                  onChange={(value) => handleSelect("About Us", value)}
                  options={header["About Us"]}
                  label="About Us"
                />
              </Grid>
              <Grid className={classes.menuButton}>
                <IconButton
                  style={{ width: 50, marginBottom: 5 }}
                  className={classes.userIcon}
                  onClick={(e) => setUserIconAnchorEl(e.currentTarget)}
                >
                  <AccountCircleIcon />
                </IconButton>
                <Typography variant="body1" style={{ marginBottom: 5 }}>
                  <Link href="/register">Register</Link>&nbsp;or&nbsp;
                  <Link href="/login">Login</Link>
                </Typography>
                <CalendlyPage>
                  <Button style={{ fontSize: 10 }}>Request a Demo</Button>
                </CalendlyPage>
              </Grid>
            </Drawer>
            <Menu
              anchorEl={userIconAnchorEl}
              keepMounted
              open={Boolean(userIconAnchorEl)}
              onClose={() => setUserIconAnchorEl(null)}
            >
              <MenuItem
                onClick={(event) => {
                  setUserIconAnchorEl(null);
                  history.push("/login");
                }}
              >
                Login
              </MenuItem>
              <MenuItem
                onClick={(event) => {
                  setUserIconAnchorEl(null);
                  history.push("/register");
                }}
              >
                Register
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </ElevationScroll>
  );
}
