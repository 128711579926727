import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import PrimaryHeading from "./Typography/PrimaryHeading";
import { AppImages } from "../../constants";

const {
  asSeenInImg: {
    asSeenInImg01,
    asSeenInImg02,
    asSeenInImg03,
    asSeenInImg04,
    asSeenInImg07,
  },
  logoProud,
} = AppImages;

const useStyle = makeStyles((theme) => ({
  asSeenInRoot: {
    padding: "105px 0 50px",
    background: "linear-gradient(180deg, #FAFAFA 0%, #F0F0F0 100%)",
  },
  asSeenInHeading: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 50,
  },
  asSeenInLogos: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  asSeenInLogo: {
    border: `1px solid ${theme.palette.primary.main}`,
    padding: 10,
    width: 350,
    height: 100,
    display: "flex",
    justifyContent: "center",
    backgroundColor: theme.palette.common.white,
    "&>img": {
      maxWidth: "100%",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      maxHeight: 80,
    },
    "&:last-child": {
      backgroundColor: "#11729F",
    },
  },
}));

export default function AsSeenInSection() {
  const classes = useStyle();
  return (
    <Box className={classes.asSeenInRoot}>
      <Box className="bodyMaxWidth">
        <Box className={classes.asSeenInHeading}>
          <PrimaryHeading>As Seen In</PrimaryHeading>
        </Box>
        <Box
          className={classes.asSeenInLogos}
          gridRowGap={20}
          gridColumnGap={30}
        >
          {[
            asSeenInImg01,
            asSeenInImg02,
            asSeenInImg03,
            asSeenInImg04,
            logoProud,
            asSeenInImg07,
          ].map((logo, key) => {
            return (
              <Box key={key} className={classes.asSeenInLogo}>
                <img src={logo} alt="" />
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}
