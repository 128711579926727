import AsSeenInSection from "../../components/Common/AsSeenInSection";
import HomePage from "../../components/NewHomePageDesign/HomePage";
import DocumentProtection from "./DocumentProtection";
import NewFooter from "./NewFooter";
import NewInsights from "./NewInsights";
import OurClient from "./OurClient";
import Partner from "./Partner";
import WhoWeArePage from "./WhoWeArePage";

const NewHomePageDesign = () => {
  return (
    <>
      <HomePage />
      <OurClient />
      <DocumentProtection />
      <WhoWeArePage />
      <NewInsights />
      <Partner />
      <AsSeenInSection />
      <NewFooter />
    </>
  );
};

export default NewHomePageDesign;
