import React, { useRef } from "react";
import { AppImages } from "../../constants";
import "../Team/style.scss";
import { Body } from "../../components/Common";

const {
  homePageImg: { bgHome },
  affiliateLogo: { logoBig, logoFoodchain, logoRhema, logoUl },
} = AppImages;

const ta = [
  {
    logo: logoUl,
    text: "UL is a global safety science leader. We deliver testing, inspection and certification (TIC), training and advisory services, risk management solutions and essential business insights to help our customers, based in more than 100 countries, achieve their safety, security and sustainability goals. We believe our deep knowledge of products and intelligence across supply chains make us the partner of choice for customers with complex challenges. UL helps the dietary supplements industry deliver quality and safety. We have brought together the services necessary to enable dietary supplement manufacturers to manage their complex supply chains and make smarter decisions. Through our cGMP certification audit programs, regulatory compliance testing and global market access programs, UL can help customers maximize their business efficiencies and meet consumer expectations.",
    link: "https://www.ul.com/industries/products-and-components/otc-and-dietary-supplements",
  },
  {
    logo: logoFoodchain,
    text: "FoodChain ID leverages our unique food safety technology, supported by our staff food safety industry experts, to save our customers time and reduce their food safety risk. Our proprietary risk assessment services include The Food Fraud Database to support GFSI-required vulnerability assessments and HorizonScan™ for daily supply chain monitoring of ingredients and supplier threats. For food safety certification and auditing, customers tell us our industry-leading service is the top reason for trusting us to support their food safety goals.",
    link: "https://www.foodchainid.com/testing/digital-pcr-for-gmo-testing/",
  },
  {
    logo: logoRhema,
    text: "Let Rhema Health Products Limited work on your most challenging formulations and you will be impressed with the results! As a custom manufacturer of tablets, capsules, flavoured powder drink mixes including effervescents, we offer a first-rate quality experience and an innovative approach to your challenges. Let our experienced team of individuals work for you to come up with innovative, market-leading formulations that will help you surpass the competition.",
    link: "https://rhemamade.com/",
  },
  {
    logo: logoBig,
    text: "Nutriient is a turnkey contract manufacturer of supplements, nutraceuticals, and foods. Nutriient offer formulation, co-packing, warehousing, and fulfillment services.",
    link: "https://www.nutriient.biz/",
  },
];

const Affiliate = () => {
  const myRef = useRef(null);

  const executeScroll = () =>
    myRef.current.scrollIntoView({ behavior: "smooth" });

  return (
    <Body seoTitle="About Us - HealthLOQ" seoDescription="About Us - HealthLOQ">
      <div className="page-affiliate">
        <div
          className="section-affiliate"
          style={{ backgroundImage: `url(${bgHome})` }}
        >
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="txt-trusted-affiliates">
                  Our <b>trusted affiliates</b> can help natural products
                  companies <b>stand out</b> from the <b>crowd</b>.<br />
                  By contributing to the <b>blockchain</b> on your behalf, they
                  will help you stand out to your customers as industry leaders
                  in <b>trust and transparency</b>.
                </div>
              </div>
            </div>
          </div>
          <div className="downScroll">
            <div class="mouse_scroll" onClick={() => executeScroll()}>
              <div class="mouse">
                <div class="wheel"></div>
              </div>
              <div>
                <span class="m_scroll_arrows unu"></span>
                <span class="m_scroll_arrows doi"></span>
                <span class="m_scroll_arrows trei"></span>
              </div>
            </div>
          </div>
        </div>
        <div className="section-affiliate01" ref={myRef}>
          <div className="container">
            {ta.map((item, i) => {
              return (
                <div className="strip-affiliate01" key={i}>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="d-flex justify-content-center align-items-center affiliates-logo">
                        <img src={item.logo} alt="" />
                      </div>
                    </div>
                    <div className="col-md">
                      <div className="txt">
                        {item.text}
                        <div>
                          <a
                            className="custom-btn btn"
                            href={item.link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Discover more
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Body>
  );
};
export default Affiliate;
