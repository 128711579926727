import { Grid, makeStyles } from "@material-ui/core";
import TitleStrip from "../../components/Common/TitleStrip";
import Feature from "./Feature";

const useStyle = makeStyles((theme) => ({
  root: {
    position: "relative",
    background: "#F7F7F7",
  },
}));

const NewInsights = () => {
  const classes = useStyle();
  return (
    <Grid className={classes.root}>
      {/* <TitleStrip title="Fresh Insights" /> */}
      <Feature />
    </Grid>
  );
};

export default NewInsights;
