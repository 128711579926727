import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import SEO from "../SEO";
import { AppConstant, AppImages } from "../../constants";

const {
  pageNotFound: { title, ButText, pageTitle, pageDes },
} = AppConstant;

const {
  icon: { Icon404 },
} = AppImages;

const PageNotFoundCallout = () => (
  <>
    <div className="page-404 d-flex align-items-center justify-content-center">
      <SEO title={pageTitle} description={pageDes} />
      <div className="text-center">
        <img src={Icon404} alt="" className="image-404" />
        <p className="text-unfortunately">{title}</p>
        <Link to="/" className="custom-btn btn">
          {ButText}
        </Link>
      </div>
    </div>
  </>
);
export default PageNotFoundCallout;
